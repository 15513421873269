import React from 'react';
import About_us from '../components/About';

const About = () => {
return (
  <div class="about-us-page-section position-relative overflow-hidden">
      {/* <h1 class="display-4 font-weight-normal">Contact</h1> */}
      <About_us />
  </div> 
);
};

export default About;
