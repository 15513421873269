import React from 'react';
import Banner from '../components/Banner';
import About_us from '../components/About';
import Projects from '../components/Projects';
import Services from '../components/Services';
import Team from '../components/Team';
import Moving from '../components/Moving_text';
import Contact_us from '../components/Contact_us';
import Instagram from '../components/Instagram';
import Word_moving from '../components/Word_moving';

const Home = () => {
return (
	<>
    <Banner />
    <About_us />
    <Word_moving />
    <Projects />
    <Services />
    <Team />
    <Contact_us />
    <Instagram />
	</>
);
};

export default Home;
