import React from 'react';
import Button from './Button';
import Slider from "react-slick";

function Services(){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true
        };
    return(
        <div className="services bg-orange">
            <div className="container">
                <div className="row services-section-top-content">
                    <div className="col-lg-4 services-section-left services-both">
                        <h5 className='grey'>Our Services</h5>
                        <h3 className='white'>Innovate, connect, inspire</h3>
                        <Button />
                    </div>
                    <div className="col-lg-8 services-section-right services-both">
                        <p>Impalawebs has placed the digital approach in the hands of the experts, 
                            who are so efficient enough to give your business a fresh booster of 
                            resourceful know how.</p>
                        <p>They are continuously researching, continuously identifying and monitoring 
                            those extensive shifts, marketing trends that provide absolute insights and 
                            gear for the clients to accomplish something and ensure the best possible 
                            outcomes in their new ventures.</p>
                    </div>
                </div>
                </div>

                <div className="services-slider">
                    <div className="container">
                        <Slider {...settings}>

                        <div className="row all-services services-1">
                            <div className="col-md-6 services-content">
                                <p><span>01</span></p>
                                <h6>DEVELOPMENT | DESIGNING</h6>
                                <h4>Web Development</h4>
                                <p>At Impala Webs, we tend to believe that the standard of 
                                    engineering and coding type the robust foundation on which 
                                    your store or app will stand on.</p>
                            </div>
                            <div className="col-md-6 services-gif">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/Development-anim.gif`} alt="Web Development"/>
                            </div> 
                        </div>

                        <div className="row all-services services-2">
                            <div className="col-md-6 services-content">
                                <p><span>02</span></p>
                                <h6>GRAPHIC | WEB DESIGNING </h6>
                                <h4>Web/Graphic Design</h4>
                                <p>We design elegant & usable websites and apps. The web experiences we create are a 
                                    result of a meticulous balance between both form and content.</p>
                            </div>
                            <div className="col-md-6 services-gif">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/design-gif-2.gif`} alt="Graphic & Web Design"/>
                            </div> 
                        </div>
                        <div className="row all-services services-3">
                            <div className="col-md-6 services-content">
                                <p><span>03</span></p>
                                <h6>ECOMMERCE | MARKETING</h6>
                                <h4>Internet Marketing</h4>
                                <p>At Impala Webs, we tend to believe that the standard of engineering and coding 
                                    type the robust foundation on which your store or app will stand on.</p>
                            </div>
                            <div className="col-md-6 services-gif">
                                <img src={`${process.env.PUBLIC_URL}/assets/images/Internet-Marketing.gif`} alt="Digital Marketing"/>
                            </div> 
                        </div>
                    </Slider>
                    </div>
                </div>
        </div>
    );
}

export default Services;