import React , {Component} from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';

import HeaderHtml from './components/Header';
import FooterHtml from './components/Footer';
import Home from './pages/Home';
import About from './pages/About';
import Gallery from './pages/Gallery';
import Contact from './pages/Contact';
import Services_section from './pages/services-section';

function App(){

  return(
  <div>
  <Router className="main-body">
	<HeaderHtml />
	<Routes>
		<Route path='/' exact element={<Home />} component={Home} />
		<Route path='/about' element={<About />} component={About} />
		<Route path='/gallery' element={<Gallery />} component={Gallery} />
		<Route path='/services-section' element={<Services_section />} component={Services_section} />
		<Route path='/contact' element={<Contact />} component={Contact} />
	</Routes>
	<FooterHtml />
	</Router>
	</div>
   
  );
}

export default App;