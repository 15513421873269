import React , { FunctionComponent, useState , useEffect} from 'react';
import Button from './Button';
import { CircularProgressbar,buildStyles } from 'react-circular-progressbar';
import {AboutSection  } from './CustomStyle/StyleComp';
    
function About_us(){
    const [percentage, setPercentage] = useState(0);
    useEffect(() => {
        setTimeout(() => {
          if (percentage < 65) {
            setPercentage(percentage + 1);
          }
        }, 10);
      }, [percentage]);

      const [percentage1, setPercentage1] = useState(0);
      useEffect(() => {
          setTimeout(() => {
            if (percentage1 < 85) {
              setPercentage1(percentage1 + 1);
            }
          }, 10);
        }, [percentage1]);

        const [percentage2, setPercentage2] = useState(0);
        useEffect(() => {
            setTimeout(() => {
              if (percentage2 < 95) {
                setPercentage2(percentage2 + 1);
              }
            }, 10);
          }, [percentage2]);

          const [percentage3, setPercentage3] = useState(0);
          useEffect(() => {
            setTimeout(() => {
              if (percentage3 < 60) {
                setPercentage3(percentage3 + 1);
              }
            }, 10);
          }, [percentage3]);

    return(
        <div className="about-us all-section bg-orange">
            <div className="container">

                <div className="row about-section-top-content">
                    <div className="col-lg-4 about-section-left about-both">
                        <h5 className='grey'>About us</h5>
                        <h3 className='white'>Who we are</h3>
                        <Button />
                    </div>
                    <div className="col-lg-8 about-section-right about-both">
                        <p>Impalawebs has placed the digital approach in the hands of the experts, 
                            who are so efficient enough to give your business a fresh booster of 
                            resourceful know how.</p>
                        <p>They are continuously researching, continuously identifying and monitoring 
                            those extensive shifts, marketing trends that provide absolute insights and 
                            gear for the clients to accomplish something and ensure the best possible 
                            outcomes in their new ventures.</p>
                    </div>
                </div>

                <div className="progress-bar">
                    <div className='d-flex align-items-center justify-content-between'>
                        <AboutSection>
                            <CircularProgressbar value={percentage} maxValue={100} minValue={0} text={`${percentage}%`} styles={buildStyles({
                            rotation: 0.25,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `rgba(62, 152, 199, ${65})`,
                            textColor: '#fff',
                            trailColor: '#FF7425',
                            backgroundColor: '#FF7425',
                        })}/>
                        </AboutSection>
                        <AboutSection>
                            <CircularProgressbar value={percentage1} maxValue={100} minValue={0} text={`${percentage1}%`} styles={buildStyles({
                            rotation: 0.25,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `rgba(62, 152, 199, ${65})`,
                            textColor: '#fff',
                            trailColor: '#FF7425',
                            backgroundColor: '#FF7425',
                        })}/>
                        </AboutSection>
                        <AboutSection><CircularProgressbar value={percentage2} maxValue={100} minValue={0}  text={`${percentage2}%`} styles={buildStyles({
                            rotation: 0.25,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `rgba(62, 152, 199, ${65})`,
                            textColor: '#fff',
                            trailColor: '#FF7425',
                            backgroundColor: '#FF7425',
                        })}/></AboutSection>
                        <AboutSection><CircularProgressbar value={percentage3} maxValue={100} minValue={0} text={`${percentage3}%`} styles={buildStyles({
                            rotation: 0.25,
                            strokeLinecap: 'butt',
                            textSize: '16px',
                            pathTransitionDuration: 0.5,
                            pathColor: `rgba(62, 152, 199, ${65})`,
                            textColor: '#fff',
                            trailColor: '#FF7425',
                            backgroundColor: '#FF7425',
                        })}/></AboutSection>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default About_us;