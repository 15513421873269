import React from 'react';
import Slider from "react-slick";

export default function Instagram(){
    const settings = {
        dots: false,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 4,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20%',
        autoplay: true,
        speed: 200,
        infinite: true,
        autoplaySpeed: 2000,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                }
              },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1 ,
              }
            }
          ]
        };
    return(
        <div className= "instagram-post-section all-section bg-orange">
            <div className="container">
                <h5 className="grey">OUR INSTAGRAM POSTS</h5>
                <h3 className="white">Creativity takes courage</h3>
            </div>

            <div className= "instagram-post-grid">
                <div className="posts-section">
                <Slider {...settings}>
                    <div className="post post-1">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-1.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-2">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-2.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-3">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-3.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-4">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-4.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-5">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-5.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-6">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-6.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-7">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-7.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-8">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-8.jpeg`} alt="instagram-post"/>
                    </div>
                    <div className="post post-9">
                        <img src= {`${process.env.PUBLIC_URL}/assets/images/post-9.jpeg`} alt="instagram-post"/>
                    </div>
                    </Slider>
                </div>
            </div>
        </div>
    );
}