import React from 'react';
import Slider from "react-slick";

export default function Team(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: false,
        slidesToShow: 2.5,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '20%',
        autoplay: true,
        speed: 300,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                }
              },
            {
              breakpoint: 767,
              settings: {
                slidesToShow: 1.5,
              }
            },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        };
    return(
        <div className="team-section all-section">
            <div className="container">
                <h5 className="orange">Dreams and teams work together</h5>
                <h3 className="orange">MEET OUR PROFESSIONALS </h3>
            </div>

            <div className="team-slider">
                    <div className="team-slider-container">
                        <Slider {...settings}>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-1.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Anju Kaundal</h4>
                            <p>Sr. Web developer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-2.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Shalakha Pradhan</h4>
                            <p>Sr. Web designer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-3.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Bhavesh Taneja</h4>
                            <p>Sr. Web developer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-4.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Pooja Bansal</h4>
                            <p>Web designer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-5.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Mahesh Verma</h4>
                            <p>Web developer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-6.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Davinder Singh</h4>
                            <p>Web developer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-7.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Jagpreet Kaur</h4>
                            <p>Web designer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-8.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Rohit seyal</h4>
                            <p>Web developer</p>
                        </div>
                        <div>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/team-9.png`} className="main-banner-img" alt="team Members"/>
                            <h4>Vikram Singh</h4>
                            <p>Sr. Web designer</p>
                        </div>
                        </Slider>
                    </div>
            </div>
        </div>
    );
}