import React from 'react';
import Button from './Button';
import Slider from "react-slick";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import "react-tabs/style/react-tabs.css";

function Projects(){
    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        arrows: true,
        slidesToShow: 3,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: '0px',
        autoplay: true,
        autoplaySpeed: 3000,
        autoplay: true,
        responsive: [
            {
                breakpoint: 1199,
                settings: {
                  slidesToShow: 2,
                }
              },
            {
              breakpoint: 575,
              settings: {
                slidesToShow: 1,
              }
            }
          ]
        };
        
    return(
        <div className="project-section all-section bg-light-black">
            <div className="container">

                <div className="row project-section-top-content">
                    <div className="col-lg-4 project-section-left project-both">
                        <h5 className='grey'>Our Works</h5>
                        <h3 className='orange'>Projects</h3>
                        <Button />
                    </div>
                    <div className="col-lg-8 project-section-right project-both">
                        <p>Impalawebs has placed the digital approach in the hands of the experts, 
                            who are so efficient enough to give your business a fresh booster of 
                            resourceful know how.</p>
                        <p>They are continuously researching, continuously identifying and monitoring 
                            those extensive shifts, marketing trends that provide absolute insights and 
                            gear for the clients to accomplish something and ensure the best possible 
                            outcomes in their new ventures.</p>
                    </div>
                </div>

                <div className="nav-slider">
                    <div className= "row project-content-section">
                        <div class="col-xl-3 col-lg-4 col-md-6 project-1 project-white-bg project">
                            <a href= "#" className="product-link">
                                <div className="project-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/project-1.jpg`} alt="Web Design"/></div>
                                <div className="project-details">
                                    <div class="content-left">
                                        <h3>Project-1</h3>
                                        <p>Web Design</p>
                                    </div>
                                    <div class="content-right">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/right_arrow.png`} alt="icon"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 project-2 project-without-bg project">
                            <a href= "#" className="product-link">
                                <div className="project-details">
                                    <div class="content-left">
                                        <h3>Project-2</h3>
                                        <p>Web Development</p>
                                    </div>
                                    <div class="content-right">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/right_arrow.png`} alt="icon"/>
                                    </div>
                                </div>
                                <div className="project-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/project-2.jpg`} alt="Web Development"/>
                                </div>
                            </a>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 project-3 project-white-bg project">
                            <a href= "#" className="product-link">
                                <div className="project-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/project-3.jpg`} alt="Web Development"/></div>
                                <div className="project-details">
                                    <div class="content-left">
                                        <h3>Project-3</h3>
                                        <p>Web Development</p>
                                    </div>
                                    <div class="content-right">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/right_arrow.png`} alt="icon"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 project-4 project-without-bg project">
                            <a href= "#" className="product-link">
                                <div className="project-details">
                                    <div class="content-left">
                                        <h3>Project-4</h3>
                                        <p>SEO</p>
                                    </div>
                                    <div class="content-right">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/right_arrow.png`} alt="icon"/>
                                    </div>
                                </div>
                                <div className="project-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/project-4.jpg`} alt="SEO"/>
                                </div>
                            </a>
                        </div>
                        <div class="col-xl-6 col-lg-4 col-md-6 project-5 project-without-bg project">
                            <a href= "#" className="product-link">
                                <div className="project-details">
                                    <div class="content-left">
                                        <h3>Project-5</h3>
                                        <p>Digital Marketing</p>
                                    </div>
                                    <div class="content-right">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/right_arrow.png`} alt="icon"/>
                                    </div>
                                </div>
                                <div className="project-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/project-5.jpg`} alt="Digital Marketing"/>
                                </div>
                            </a>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 project-6 project-without-bg project">
                            <a href= "#" className="product-link">
                                <div className="project-details">
                                    <div class="content-left">
                                        <h3>Project-6</h3>
                                        <p>Web Design</p>
                                    </div>
                                    <div class="content-right">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/right_arrow.png`} alt="icon"/>
                                    </div>
                                </div>
                                <div className="project-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/project-6.jpg`} alt="Web Design"/>
                                </div>
                            </a>
                        </div>
                        <div class="col-xl-3 col-lg-4 col-md-6 project-7 project-white-bg project">
                            <a href= "#" className="product-link">
                                <div className="project-image">
                                    <img src={`${process.env.PUBLIC_URL}/assets/images/project-2.jpg`} alt="Web Development"/></div>
                                <div className="project-details">
                                    <div class="content-left">
                                        <h3>Project-7</h3>
                                        <p>Web Development</p>
                                    </div>
                                    <div class="content-right">
                                        <img src={`${process.env.PUBLIC_URL}/assets/images/right_arrow.png`} alt="icon"/>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </div>
                </div> 

            </div>
        </div>
    );
}

export default Projects;