import { useState, useEffect } from "react";
import Slider from "react-slick";
import Typewriter from "typewriter-effect";
import Button from './Button';

export default function Banner() {
    var settings = {
        dots: true,
        arrows: false,
        vertical: true,
        verticalSwiping: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        speed: 300,
        infinite: true,
        autoplaySpeed: 3000,
        autoplay: true
      };
   
    return (
        <div className="banner-section">
        <div className= "hero-section">
            <div className="banner-left both-banner">
                <div className="container">
                    <div className="top-banner-head d-flex">
                        <div className="orange-banner-bg">
                            <img src={`${process.env.PUBLIC_URL}/assets/images/Orange-Splash-section.png`} className= "orange-banner-img" alt="orange-banner"/>
                        </div>
                        <p>Responsive and retina ready style works for almost all the customers 
                            and cut the operating cost to the minimal. Plugin features and the 
                            image effects make the most of it.</p>
                    </div>
                    <div className="main-title">
                        <h2>WE CREATE</h2>
                        <h1 className="typewriter-heading">
                        <Typewriter
                        options={{
                            strings: ['WEBSITE', 'DESIGN', 'App'],
                            autoStart: true,
                            loop: true,
                          }}
                           
                        />
                        </h1>
                        <Button />
                    </div>
                </div>
            </div>

           
                <Slider {...settings}>
                <div className="banner-right both-banner">
                
                    <p><img src={`${process.env.PUBLIC_URL}/assets/images/Banner-1.jpg`} className="main-banner-img" alt="hero-banner"/></p>;
                    </div> 
                    <div className="banner-right both-banner">
               
                    <p><img src={`${process.env.PUBLIC_URL}/assets/images/Banner-2.jpeg`} className="main-banner-img" alt="hero-banner"/></p>;
                    </div> 
                    <div className="banner-right both-banner">
                
                    <p><img src={`${process.env.PUBLIC_URL}/assets/images/Banner-3.jpg`} className="main-banner-img" alt="hero-banner"/></p>;
                    </div>
                    </Slider>
                

                <div className="Social-media-names">
                    <ul className="d-flex">
                        <li><a href="https://www.facebook.com/impalawebs" >FACEBOOK</a></li>
                        <li><a href="https://www.linkedin.com/company/impala-webs/" >LINKEDIN</a></li>
                        <li><a href="https://www.instagram.com/impalawebs/" >INSTAGRAM</a></li>
                    </ul>
                </div>
          
        </div>

        
    </div>
       
    )
}
