import styled from 'styled-components';



export const AboutSection = styled.div`
  & {
    position: relative;
    margin:1rem;
  }
  &:after {
    position: absolute;
    z-index: 1;
    content: "65%";
    background-image: url(${process.env.PUBLIC_URL}/assets/images/about-icon.png);
    background-repeat: no-repeat;
    background-position: top;
    top: 50%;
    background-size: 40px 40px;
    left: 50%;
    height: 35%;
    width: 40%;
    transform: translate(-50%, -50%);
    color: rgb(255, 255, 255);
    font-size: 30px;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    font-weight: bold;
  }
  &:before {
    position: absolute;
    content: '';
    display: block;
    top: 50%;
    left: 50%;
    background-color: var(--dark-orange);
    transform: translate(-50%, -50%);
    border-radius: 50%;
    width: 50%;
    height: 50%;
    z-index: 1;
    box-shadow: 0px 0px 20px 5px rgba(0, 0, 0, 0.15);
  }
  &:nth-child(even):before{
    background-color: var(--brown);
  }
  &:nth-child(even) .CircularProgressbar-path ,
  &:nth-child(even) .CircularProgressbar-trail
{
    stroke: var(--brown)!important;
}
&:nth-child(2):after {
  content: "85%";
}
&:nth-last-child(2):after {
  content: "95%";
}
&:last-child:after {
  content: "60%";
}
 
@media screen and (min-width: 1599px){
  &:after{
          background-size: 65px 65px;
          font-size: 36px;
    }
  }
@media screen and (min-width: 1399px){
  &:after{
          background-size: 60px 60px;
    }
}
@media screen and (max-width: 1199px){
  &:after {
    background-size: 30px 30px;
    font-size: 22px;
  }
}
@media screen and (max-width: 991px){
  &::before {
    width: 45%;
    height: 45%;
  }
  &::after {
    background-size: 28px 28px;
    font-size: 18px;
  }
}
@media screen and (max-width: 767px){
  &{
    width: 38%;
  }
  &::after {
    background-size: 44px 44px;
    font-size: 21px;
  }
  &:nth-last-child(2){order: 4;}
}
@media screen and (max-width: 600px){
  &::after {
    background-size: 41px 41px;
    font-size: 18px;
  }
}
@media screen and (max-width: 575px){
  &::after {
    background-size: 35px 35px;
  }
}
@media screen and (max-width: 480px){
  &{
    width: 60%;
    margin: 1rem auto;
  }
  &::after {
    background-size: 54px 54px;
    font-size: 24px;
  }
  &:nth-last-child(2) {
    order: 0;
  }
}
@media screen and (max-width: 440px){
  &{
    width: 70%;  }
}
@media screen and (max-width: 380px){
  &{
    width: 90%;  }
}
@media screen and (max-width: 340px){
  &{
    width: 83%;  }
    &::after {
      background-size: 50px 50px;
    }
}
`;

