import React from 'react';
import Newsletter from '../Newsletter';

const FooterHtml = () => {
  return (
    <>
      <footer>
         <div className="footer bg-light-black">
            <div className="container">
               <div className="row top-footer-content">
                  <div className="col-lg-4">
                     <div className="footer-block-1 footer-block">
                        <ul className="footer-links">
                           <li><a href="/" >Home</a></li>
                           <li><a href="/about" >About Us</a></li>
                           <li><a href="/gallery" >Gallery</a></li>
                           <li><a href="/contact" >Contact Us</a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="footer-block-2 footer-block text-center">
                        <p><a href="/" ><img src={`${process.env.PUBLIC_URL}/assets/images/logo_white.svg`} alt="Impala Webs Logo"/></a></p>
                        <ul className="social-icons d-flex align-items-center justify-content-center">
                           <li><a href="https://www.facebook.com/impalawebs" >
                              <img src={`${process.env.PUBLIC_URL}/assets/images/facebook.png`} alt="facebook"/></a>
                           </li>
                           <li><a href="https://twitter.com/impalawebs" >
                              <img src={`${process.env.PUBLIC_URL}/assets/images/twitter.png`} alt="twitter"/></a></li>
                           <li><a href="https://www.instagram.com/impalawebs/" >
                              <img src={`${process.env.PUBLIC_URL}/assets/images/instagram.png`} alt="instagram"/></a></li>
                           <li><a href="https://www.skype.com/impalawebs" >
                              <img src={`${process.env.PUBLIC_URL}/assets/images/skype.png`} alt="skype"/></a></li>
                        </ul>
                     </div>
                  </div>
                  <div className="col-lg-4">
                     <div className="footer-block-3 footer-block text-left">
                        <ul className="footer-links">
                           <li><img src={`${process.env.PUBLIC_URL}/assets/images/address.png`} alt="address-icon"/>
                           SCO 823, 2nd Floor NAC, Housing Board, Chandigarh.</li>
                           <li><img src={`${process.env.PUBLIC_URL}/assets/images/phone.png`} alt="phone-icon"/>
                              <a href="tel:+91(828)482-74-57" className='phone-no-1'>+91 (828) 482-74-57</a>
                              <a href="tel:+0(172)350-06-41" className='phone-no-2'>+0 (172) 350-06-41</a>
                           </li>
                           <li><img src={`${process.env.PUBLIC_URL}/assets/images/message.png`} alt="message-icon"/>
                           <a href="mailto:career@impalawebs.com">career@impalawebs.com</a>
                           </li>
                        </ul>
                     </div>
                  </div> 
               </div>
               {/* <div className="newsletter text-center">
                  <h4>Newsletter</h4>
                  <Newsletter />
               </div> */}
            </div>
            <div className="copyright text-center">
               <div className="container">
                  <div className="row">
                     <div className="col-md-12">
                        <p>Copyright © 2023 <a href="/" >Impala Webs</a>. All Rights Reserved.</p>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </footer>
    </>
  );
};
  
export default FooterHtml;
