import React from 'react';
import Services from '../components/Services';

const Services_section = () => {
return (
  <div class="services-us-page-section position-relative overflow-hidden">
      {/* <h1 class="display-4 font-weight-normal">Contact</h1> */}
      <Services />
  </div> 
);
};

export default Services_section;