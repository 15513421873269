import React from 'react';

export default function Contact_us(){
    return(
        <div className="contact-us" id="contact-us">
            <div className="row contact-container">
                <div className="col-md-5 contact-left">
                    <img src={`${process.env.PUBLIC_URL}/assets/images/contact-us.gif`} alt="contact-gif" />
                </div>

                <div className="col-md-7 contact-right">
                    <div className="container">
                        <h2>
                            <img src={`${process.env.PUBLIC_URL}/assets/images/arrow-right.png`} alt="contact-gif"/>
                            LET’S LEVEL UP
                            <span>YOUR BRAND, TODAY</span>
                        </h2>
                        <form>
                            <label for="name">Name
                                <input type="text" placeholder="Your Name" id="name" name="name"/>
                            </label>
                            <label for="email">Email
                                <input type="email" placeholder="you@company.com" id="email" name="email"/>
                            </label>
                            <label for="tel">Phone
                                <input type="text" placeholder="000-000-0000" id="phone" name="phone" pattern="[0-9]{3}-[0-9]{2}-[0-9]{3}"/>
                            </label>
                            <input type="submit" id="submit_btn" className="submit_btn"></input>
                        </form>
                    </div>
                </div>

            </div>
        </div>
    );
}
