import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import 'react-tooltip/dist/react-tooltip.css';
import { slide as Menu } from 'react-burger-menu';

const HeaderHtml = () => {
  const [basicOpen, setBasicOpen] = useState(true);
  const [basicCollapse1, setBasicCollapse1] = useState(true);
  const [basicCollapse2, setBasicCollapse2] = useState(false);
  

  return (
    <>
      <div className="main-header">
      <header className="header">
        <div className="row">
          <div className="col-6 main-logo">
                <a href='/' className="logo-link" >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="logo-brand" alt="Impala Webs Logo"/>
                </a>
          </div>
          <div className="col-6 menu-bar">
            <div className="container">
              <ul className="menu-bar-items">
                <li className="active">
                  <a href='/' className="menu-link home" activeStyle  >
                    <img src={`${process.env.PUBLIC_URL}/assets/images/home.svg`} alt="home"/></a>
                  </li>
                <li><a href='/about' className="menu-link about" activeStyle >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/Service.svg`} alt="about"/></a>
                  </li>
                <li><a href='/services-section' className="menu-link services" activeStyle >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/Gallery.svg`} alt="services"/></a>
                  </li>
                <li><a href='/contact' className="menu-link contact" activeStyle >
                  <img src={`${process.env.PUBLIC_URL}/assets/images/Contact.svg`}  alt="contact"/></a>
                  </li>
                {/* <li><a href="" className="menu-link menu">
                  <img src={`${process.env.PUBLIC_URL}/assets/images/menu.svg`} /></a>
                  </li> */}
              </ul>

                <div className="main-menu-section">
                  <div id="myLinks">
                    <Menu>
                      <div class="side-bar-logo">
                        <NavLink  className="logo-link" to='/' > 
                          <img src={`${process.env.PUBLIC_URL}/assets/images/logo.svg`} className="logo-brand" alt="Impala Webs Logo"/>
                        </NavLink>
                      </div>
                      <ul>
                        <li>
                          <NavLink  className="menu-link menu-link-2" to='/' activeStyle >
                          <img src={`${process.env.PUBLIC_URL}/assets/images/home.svg`} alt="home"/>Home
                          </NavLink>
                        </li>
                      <li>
                          <NavLink  className="menu-link menu-link-2" to='/about' activeStyle >
                          <img src={`${process.env.PUBLIC_URL}/assets/images/Service.svg`} alt="about"/>About
                          </NavLink>
                        </li> 
                      <li>
                          <NavLink  className="menu-link menu-link-2" to='/services-section' activeStyle >
                          <img src={`${process.env.PUBLIC_URL}/assets/images/Gallery.svg`} alt="services"/>Services
                          </NavLink>
                      </li> 
                      <li>
                          <NavLink  className="menu-link menu-link-2" to='/contact' activeStyle >
                          <img src={`${process.env.PUBLIC_URL}/assets/images/Contact.svg`} alt="contact"/>Contact
                          </NavLink>
                      </li>
                      </ul>

                      <div className="socail-icons-header">
                        <ul className="social-icons d-flex align-items-center justify-content-start">
                           <li><a href="https://www.facebook.com/impalawebs" >
                              <img src={`${process.env.PUBLIC_URL}/assets/images/facebook.png`} alt="facebook"/></a>
                           </li>
                           <li><a href="https://twitter.com/impalawebs" >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/twitter.png`} alt="twitter"/></a></li>
                           <li><a href="https://www.instagram.com/impalawebs/" >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/instagram.png`} alt="instagram"/></a></li>
                           <li><a href="https://www.skype.com/impalawebs" >
                            <img src={`${process.env.PUBLIC_URL}/assets/images/skype.png`} alt="skype"/></a></li>
                        </ul>
                     </div>
                    </Menu>
                </div>
              </div>
            </div>

          </div>
        </div>
      </header>
    </div>
    <Tooltip anchorSelect=".menu-link" />
    </>
  );
};
  
export default HeaderHtml;
