import React from 'react';
import Contact_us from '../components/Contact_us';

const Contact = () => {
return (
<>
  <div class="contact-us-page-section position-relative overflow-hidden">
      {/* <h1 class="display-4 font-weight-normal">Contact</h1> */}
      <Contact_us />
  </div> 
	
	</>
);
};

export default Contact;
